import { useFirebase } from "context/firebase.context";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";

export function useCreators() {
  const { firestore } = useFirebase();
  const [creators, setCreators] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const q = query(
      collection(firestore, "creators"),
      orderBy("updatedAt", "desc"),
    );

    const unsubscribe = onSnapshot(q, {
      next: (snapshot) => {
        const creatorsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCreators(creatorsData);
        setIsLoading(false);
      },
      includeMetadataChanges: true,
    });

    return () => unsubscribe();
  }, [firestore]);

  return { creators, isLoading };
}
