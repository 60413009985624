import {
    Box,
    Button,
    Circle,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Progress,
    SimpleGrid,
    Square,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import FAIcon from "@components/FAIcon";
import _ from "lodash";
import {ulid} from "ulid";
import useAPI from "hooks/api";
import getPublicDownloadUrl from "libs/get-public-download-url";

const defaultOptions = {
    play: true,
    forward: true,
    backward: true,
    download: true,
    price: false,
    savers: false,
    likers: false,
    trash: false,
    close: false,
    review: false,
};

export default function VideoPlayer({
                                        streamUrl,
                                        video = null,
                                        creatorId = null,
                                        downloadUrl = null,
                                        autoPlay = false,
                                        onDelete = () => {
                                        },
                                        onClose = () => {
                                        },
                                        options = {},
                                    }) {
    const api = useAPI();
    const videoRef = useRef(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [creator, setCreator] = useState(null);

    const {
        isOpen: confirmDeleteModalOpen,
        onOpen: openConfirmDeleteModal,
        onClose: closeConfirmDeleteModal,
    } = useDisclosure();

    const uiSettings = useMemo(
        () =>
            Object.fromEntries(
                Object.entries(defaultOptions).map(([key, value]) => [
                    key,
                    options[key] ?? value,
                ]),
            ),
        [options],
    );

    const onTimeUpdate = useCallback(() => {
        if (videoRef.current) {
            setCurrentTime(videoRef.current.currentTime);
            setDuration(videoRef.current.duration);
        }
    }, [videoRef]);

    const onPlay = useCallback(() => {
        if (videoRef.current) {
            setIsPlaying(!videoRef.current.paused);
        }
    }, [videoRef, setIsPlaying]);

    const onPause = useCallback(() => {
        if (videoRef.current) {
            setIsPlaying(!videoRef.current.paused);
        }
    }, [videoRef, setIsPlaying]);

    const play = useCallback(() => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    }, [videoRef]);

    const pause = useCallback(() => {
        if (videoRef.current) {
            videoRef.current.pause();
        }
    }, [videoRef]);

    const forward = useCallback(() => {
        if (videoRef.current) {
            videoRef.current.currentTime = Math.min(
                videoRef.current.currentTime + 5,
                videoRef.current.duration,
            );
        }
    }, [videoRef]);

    const backward = useCallback(() => {
        if (videoRef.current) {
            videoRef.current.currentTime = Math.max(
                videoRef.current.currentTime - 5,
                0,
            );
        }
    }, [videoRef]);

    const loadCreator = useCallback(async () => {
        if (!creatorId) return;

        const creator = await api.getCreator(creatorId);
        setCreator(creator);
    }, [api, creatorId]);

    const deleteVideo = useCallback(async () => {
        await api.deleteVideo(video.id);
        onDelete();
        onClose();
    }, [api, onClose, onDelete, video]);

    const download = useCallback(() => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = function (event) {
            var blob = xhr.response;
            const url = window.URL.createObjectURL(blob);
            // 創建一個臨時的 a 標籤用於觸發下載
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = _.get(video, "id") || ulid(); // 指定下載的文件名稱，你可以根據實際情況修改檔名和擴展名
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        };
        xhr.open("GET", downloadUrl);
        xhr.send();
    }, [downloadUrl, video]);

    useEffect(() => {
        loadCreator();
    }, [loadCreator]);

    useEffect(() => {
        if (autoPlay && videoRef.current) {
            videoRef.current.play();
        }
    }, [autoPlay]);

    return (
        <Box
            backgroundColor={"black"}
            width={"full"}
            position={"relative"}
            borderRadius={"md"}
            height={"100%"}
            overflow={"hidden"}
        >
            <video
                ref={videoRef}
                onPlay={onPlay}
                onPause={onPause}
                onEnded={onPause}
                onTimeUpdate={onTimeUpdate}
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    width: "100%",
                }}
                src={streamUrl}
            ></video>
            <Box
                position={"absolute"}
                width={"full"}
                height={"full"}
                top={0}
                left={0}
            >
                <Box position={"absolute"} bottom={0} left={0} width={"full"}>
                    <Box position="relative" width="full" height="8px" bg="whiteAlpha.300">
                        <Box
                            as="div"
                            width="full"
                            height="4px"
                            position="absolute"
                            top="0"
                            cursor="pointer"
                            onClick={(e) => {
                                const rect = e.currentTarget.getBoundingClientRect();
                                const x = e.clientX - rect.left;
                                const percentage = x / rect.width;
                                if (videoRef.current) {
                                    videoRef.current.currentTime = percentage * duration;
                                }
                            }}
                            _hover={{height: "6px"}}
                        >
                            <Progress
                                value={duration ? Math.floor((currentTime / duration) * 100) : 0}
                                min={0}
                                max={100}
                                height="full"
                                colorScheme="red"
                                hasStripe={false}
                                isAnimated={false}
                                sx={{
                                    "& > div": {
                                        transition: "all 0.2s",
                                        background: "red.500",
                                    },
                                    "& > div > div": {
                                        background: "red.500",
                                    }
                                }}
                                draggable
                                onDragStart={(e) => {
                                    const img = new Image();
                                    e.dataTransfer.setDragImage(img, 0, 0);
                                }}
                                onDrag={(e) => {
                                    if (e.clientX === 0) return;
                                    const rect = e.currentTarget.getBoundingClientRect();
                                    const x = Math.max(0, Math.min(e.clientX - rect.left, rect.width));
                                    const percentage = x / rect.width;
                                    if (videoRef.current) {
                                        videoRef.current.currentTime = percentage * duration;
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                </Box>

                <Flex
                    position={"absolute"}
                    top={0}
                    left={0}
                    alignItems={"center"}
                    padding={4}
                >
                    <Circle
                        size={12}
                        backgroundColor={"black"}
                        backgroundImage={`url('${getPublicDownloadUrl(_.get(creator, "picture", null))}')`}
                        backgroundPosition={"center"}
                        backgroundSize={"cover"}
                    ></Circle>
                    <Box marginLeft={2} color={"white"}>
                        <Text fontSize={"sm"}>
                            {_.get(creator, "displayName", "未命名的創作者")}
                        </Text>
                        <Text fontSize={"xs"} marginTop={0}>
                            @{_.get(creator, "slug", "anonymous")}
                        </Text>
                    </Box>
                </Flex>
                <SimpleGrid position={"absolute"} right={4} bottom={4} spacing={2}>
                    {uiSettings["price"] && (
                        <Flex
                            flexDirection={"column"}
                            width={12}
                            justifyContent={"center"}
                            alignItems={"center"}
                            cursor={"pointer"}
                        >
                            <Text fontSize={"2xl"}>
                                <FAIcon name={"gem"} group={"solid"}/>
                            </Text>
                            <Text fontSize={"xs"} fontWeight={"bold"}>
                                {video.price}
                            </Text>
                        </Flex>
                    )}
                    {uiSettings["savers"] && (
                        <Flex
                            flexDirection={"column"}
                            width={12}
                            justifyContent={"center"}
                            alignItems={"center"}
                            cursor={"pointer"}
                        >
                            <Text fontSize={"2xl"}>
                                <FAIcon name={"bookmark"} group={"solid"}/>
                            </Text>
                            <Text fontSize={"xs"} fontWeight={"bold"}>
                                {/* @todo: get data from API */}
                                --
                            </Text>
                        </Flex>
                    )}
                    {uiSettings["likers"] && (
                        <Flex
                            flexDirection={"column"}
                            width={12}
                            justifyContent={"center"}
                            alignItems={"center"}
                            cursor={"pointer"}
                        >
                            <Text fontSize={"2xl"}>
                                <FAIcon name={"heart"} group={"solid"}/>
                            </Text>
                            <Text fontSize={"xs"} fontWeight={"bold"}>
                                {/* @todo: get data from API */}
                                --
                            </Text>
                        </Flex>
                    )}
                    {uiSettings["trash"] && (
                        <Square size={12} fontSize="2xl" onClick={openConfirmDeleteModal}>
                            <FAIcon name="trash-can" group="solid" color="red.600"/>
                        </Square>
                    )}
                    {uiSettings["download"] && (
                        <Square
                            size={12}
                            fontSize={"2xl"}
                            cursor={"pointer"}
                            onClick={download}
                        >
                            <FAIcon name={"download"} group={"solid"}/>
                        </Square>
                    )}
                    {uiSettings["close"] && (
                        <Square
                            size={12}
                            fontSize={"2xl"}
                            cursor={"pointer"}
                            onClick={onClose}
                        >
                            <FAIcon name={"times"} group={"solid"}/>
                        </Square>
                    )}
                </SimpleGrid>
                <SimpleGrid
                    position={"absolute"}
                    left={4}
                    bottom={4}
                    rowGap={2}
                    maxWidth={"calc(100% - 5rem)"}
                >
                    {uiSettings["review"] && (
                        <Flex
                            padding={2}
                            backgroundColor={"red.500"}
                            flexDirection={"column"}
                            color={"white"}
                            borderRadius={"sm"}
                            fontSize={"sm"}
                            columnGap={2}
                        >
                            <Text>本影片已被拒絕</Text>
                            <Text noOfLines={1}>拒絕原因：拒絕原因</Text>
                        </Flex>
                    )}
                    <SimpleGrid columns={3} width={"max-content"}>
                        {uiSettings["backward"] && (
                            <Square
                                size={12}
                                fontSize={"2xl"}
                                onClick={backward}
                                cursor={"pointer"}
                            >
                                <FAIcon name={"backward"} group={"solid"}/>
                            </Square>
                        )}
                        {uiSettings["play"] &&
                            (isPlaying ? (
                                <Square
                                    size={12}
                                    fontSize={"2xl"}
                                    onClick={pause}
                                    cursor={"pointer"}
                                >
                                    <FAIcon name={"pause"} group={"solid"}/>
                                </Square>
                            ) : (
                                <Square
                                    size={12}
                                    fontSize={"2xl"}
                                    onClick={play}
                                    cursor={"pointer"}
                                >
                                    <FAIcon name={"play"} group={"solid"}/>
                                </Square>
                            ))}
                        {uiSettings["forward"] && (
                            <Square
                                size={12}
                                fontSize={"2xl"}
                                onClick={forward}
                                cursor={"pointer"}
                            >
                                <FAIcon name={"forward"} group={"solid"}/>
                            </Square>
                        )}
                    </SimpleGrid>
                </SimpleGrid>
                <Modal
                    isOpen={confirmDeleteModalOpen}
                    onClose={closeConfirmDeleteModal}
                    isCentered
                >
                    <ModalOverlay/>
                    <ModalBody>
                        <ModalContent p={6} width={80}>
                            <Box textAlign="center" fontSize="xl" pb={6}>
                                確定刪除影片？
                            </Box>
                            <Flex justify="center" gap={3}>
                                <Button onClick={closeConfirmDeleteModal}>取消</Button>
                                <Button colorScheme="red" onClick={deleteVideo}>
                                    確認
                                </Button>
                            </Flex>
                        </ModalContent>
                    </ModalBody>
                </Modal>
            </Box>
        </Box>
    );
}
