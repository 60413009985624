import {useCallback, useMemo} from "react";
import {useFirebase} from "context/firebase.context";
import {getIdToken} from "firebase/auth";
import _ from "lodash";

const BACKEND_SERVER = process.env.REACT_APP_ADMIN_API_BASE_URL;

const useAPI = () => {
    const {auth} = useFirebase();

    const apiRequest = useCallback(
        async (endpoint, options) => {
            if (!auth.currentUser) return null;
            const token = await getIdToken(auth.currentUser);
            return fetch(`${BACKEND_SERVER}${endpoint}`, {
                ...options,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }).then(async (response) => {
                const accepted = [200, 201];
                if (accepted.includes(response.status)) return await response.json();
                if (response.status === 204) return null;
                const responseBody = await response.text();
                try {
                    return JSON.parse(responseBody);
                } catch (error) {
                    return responseBody;
                }
            });
        },
        [auth.currentUser],
    );

    // let apiRequest to be a dependency of api object,
    // so that when apiRequest change (aka auth changed),
    // all api functions get updated
    const api = useMemo(() => ({apiRequest}), [apiRequest]);

    api.claimVideoToReview = useCallback(
        async () =>
            apiRequest(`/review/videos/claim`, {
                method: "POST",
            }),
        [apiRequest],
    );

    api.getDepositsAnalytics = useCallback(
        async (start) => apiRequest(`/analytics/deposits?start=${start}`),
        [apiRequest],
    );

    api.getReviewPendingVideosCount = useCallback(
        async () => apiRequest(`/review/videos/count`),
        [apiRequest],
    );

    api.reviewVideo = useCallback(
        async (id, payload) =>
            apiRequest(`/videos/${id}/review`, {
                method: "POST",
                body: JSON.stringify(payload),
            }),
        [apiRequest],
    );

    api.createCreator = useCallback(
        async (payload) =>
            apiRequest(`/creators/`, {
                method: "POST",
                body: JSON.stringify(payload),
            }),
        [apiRequest],
    );

    api.getCreator = useCallback(
        async (id) => apiRequest(`/creators/${id}`),
        [apiRequest],
    );

    api.getCreators = useCallback(
        async () => apiRequest(`/creators`),
        [apiRequest],
    );

    api.getCreatorTransactions = useCallback(
        async (id) => apiRequest(`/creators/${id}/transactions`),
        [apiRequest],
    );

    api.getCreatorTxsAnalytics = useCallback(
        async (id) => apiRequest(`/creators/${id}/transactions/analytics`),
        [apiRequest],
    );

    api.getVideo = useCallback(
        async (id) => apiRequest(`/videos/${id}`),
        [apiRequest],
    );

    api.getVideos = useCallback(
        async (next) => apiRequest(`/videos?next=${next}`),
        [apiRequest],
    );

    api.deleteVideo = useCallback(
        async (id) => apiRequest(`/videos/${id}`, {method: "DELETE"}),
        [apiRequest],
    );

    api.getCreatorVideos = useCallback(
        async (id, options) => {
            const queries = [];
            const next = _.get(options, "next");
            if (next) queries.push(`next=${next}`);
            return apiRequest(`/creators/${id}/videos?${queries.join("&")}`);
        },
        [apiRequest],
    );

    api.resetVideoSearchEngine = useCallback(
        async () =>
            apiRequest(`/search-engine/videos`, {
                method: "DELETE",
            }),
        [apiRequest],
    );

    api.resetCreatorSearchEngine = useCallback(
        async () =>
            apiRequest(`/search-engine/creators`, {
                method: "DELETE",
            }),
        [apiRequest],
    );

    api.getCreatorFollowers = useCallback(
        async (id) => apiRequest(`/creators/${id}/followers`),
        [apiRequest],
    );

    api.createNotification = useCallback(
        async (payload) =>
            apiRequest(`/notifications`, {
                method: "POST",
                body: JSON.stringify(payload),
            }),
        [apiRequest],
    );

    api.updateNotification = useCallback(
        async (id, payload) =>
            apiRequest(`/notifications/${id}`, {
                method: "PATCH",
                body: JSON.stringify(payload),
            }),
        [apiRequest],
    );

    api.getNotifications = useCallback(
        async () => apiRequest(`/notifications`),
        [apiRequest],
    );

    api.getNotification = useCallback(
        async (id) => apiRequest(`/notifications/${id}`),
        [apiRequest],
    );

    api.getDeposit = useCallback(
        async (id) => apiRequest(`/deposits/${id}`),
        [apiRequest],
    );
    api.deposit = useCallback(
        async (id, amount) =>
            apiRequest(`/users/${id}/deposits`, {
                method: "POST",
                body: JSON.stringify({amount}),
            }),
        [apiRequest],
    );

    api.getUser = useCallback(
        async (id) => apiRequest(`/users/${id}`),
        [apiRequest],
    );
    api.getTransaction = useCallback(
        async (id) => apiRequest(`/transactions/${id}`),
        [apiRequest],
    );
    api.getCreatorChats = useCallback(
        async (id) => apiRequest(`/creators/${id}/chatrooms`),
        [apiRequest],
        [],
    );

    api.getChatMessages = useCallback(
        async (id) => apiRequest(`/chatrooms/${id}/messages`),
        [apiRequest],
        [],
    );

    api.claimPreRegistrationReward = useCallback(
        async (payload) =>
            apiRequest(`/pre-registration-reward/claim`, {
                method: "POST",
                body: JSON.stringify(payload),
            }),
        [apiRequest],
    );

    api.getGuestModeVideos = useCallback(
        async () => apiRequest(`/guest-mode/videos`),
        [apiRequest],
    );

    api.addGuestModeVideo = useCallback(
        async (id) => apiRequest(`/guest-mode/videos/${id}`, {method: "POST"}),
        [apiRequest],
    );

    api.getExploreSettings = useCallback(
        async () => apiRequest(`/explore`),
        [apiRequest],
    );

    api.getExploreSettingsByChunk = useCallback(
        async (index, offset = 0, limit = 5) =>
            apiRequest(`/explore/chunks/${index}?offset=${offset}&limit=${limit}`),
        [apiRequest],
    );

    api.createExploreSettingsBanner = useCallback(
        async (payload) =>
            apiRequest(`/explore/banners`, {
                method: "POST",
                body: JSON.stringify(payload),
            }),
        [apiRequest],
    );

    api.removeExploreSettingsBanner = useCallback(
        async (index) =>
            apiRequest(`/explore/banners/${index}`, {method: "DELETE"}),
        [apiRequest],
    );

    api.removeExploreSettingsGroup = useCallback(
        async (index) =>
            apiRequest(`/explore/groups/${index}`, {method: "DELETE"}),
        [apiRequest],
    );

    api.createExploreSettingsGroup = useCallback(
        async (payload) =>
            apiRequest(`/explore/groups`, {
                method: "POST",
                body: JSON.stringify(payload),
            }),
        [apiRequest],
    );

    api.swapExploreSettingsGroup = useCallback(
        async (first, second) =>
            apiRequest(`/explore/swap-groups`, {
                method: "PATCH",
                body: JSON.stringify({first, second}),
            }),
        [apiRequest],
    );

    api.updateExploreSettingsGroup = useCallback(
        async (index, payload) =>
            apiRequest(`/explore/groups/${index}`, {
                method: "PATCH",
                body: JSON.stringify(payload),
            }),
        [apiRequest],
    );

    api.removeGuestModeVideo = useCallback(
        async (id) => apiRequest(`/guest-mode/videos/${id}`, {method: "DELETE"}),
        [apiRequest],
    );

    api.getTestAccounts = useCallback(
        async () => apiRequest(`/developers/accounts`),
        [apiRequest],
    );

    api.grantTestAccountPoints = useCallback(
        async (index, amount) =>
            apiRequest(`/developers/accounts/${index}/grant`, {
                method: "POST",
                body: JSON.stringify({amount}),
            }),
        [apiRequest],
    );

    api.cancelVIP = useCallback(
        async (id) =>
            apiRequest(`/users/${id}/vip/cancel`, {
                method: "POST",
            }),
        [apiRequest],
    );

    api.deleteVIP = useCallback(
        async (id) =>
            apiRequest(`/users/${id}/vip`, {
                method: "DELETE",
            }),
        [apiRequest],
    );

    api.disableUser = useCallback(
        async (id) =>
            apiRequest(`/users/${id}/disable`, {
                method: "POST",
            }),
        [apiRequest],
    );

    api.enableUser = useCallback(
        async (id) =>
            apiRequest(`/users/${id}/enable`, {
                method: "POST",
            }),
        [apiRequest],
    );

    api.getUserStatus = useCallback(
        async (id) => apiRequest(`/users/${id}/status`),
        [apiRequest],
    );

    return useMemo(() => api, [api]);
};

export default useAPI;
