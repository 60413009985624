import {
    Box,
    Button,
    Circle,
    CircularProgress,
    Flex,
    SimpleGrid,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useToast,
} from "@chakra-ui/react";
import {ChatsList} from "components/creator/ChatsList";
import MainLayout from "@layouts/main.layout";
import FAIcon from "@components/FAIcon";
import useAPI from "hooks/api";
import getPublicDownloadUrl from "libs/get-public-download-url";
import _ from "lodash";
import {useCallback, useEffect, useState} from "react";
import {useVideoCountByCreatorId} from "hooks/useVideoCountByCreatorId";
import {useNavigate, useParams} from "react-router-dom";
import dayjs from "dayjs";
import {useFirebase} from "context/firebase.context";
import CreatorWallet from "../../components/creator/CreatorWallet";
import {doc, onSnapshot} from "firebase/firestore";
import {deleteCreator} from "../../libs/delete-creator";
import {VideosList} from "../../components/creator/VideosList";

const FollowersList = ({id}) => {
    const api = useAPI();
    const [followers, setFollowers] = useState([]);

    const loadCreatorFollowers = useCallback(async () => {
        const result = await api.getCreatorFollowers(id);
        if (result) setFollowers(result.data);
    }, [api, id]);

    useEffect(() => {
        loadCreatorFollowers();
    }, [loadCreatorFollowers]);

    return (
        <SimpleGrid spacing={2}>
            {followers.map((follower) => {
                return (
                    <Flex
                        justifyContent={"space-between"}
                        key={_.get(follower, "user.id")}
                        alignItems={"center"}
                    >
                        <Flex alignItems={"center"} columnGap={4}>
                            <Circle
                                size={12}
                                backgroundColor={"black"}
                                backgroundImage={
                                    _.get(follower, "user.picture")
                                        ? getPublicDownloadUrl(_.get(follower, "user.picture"))
                                        : null
                                }
                                backgroundPosition={"center"}
                                backgroundSize={"cover"}
                            ></Circle>
                            <Box>
                                <Text fontWeight={"semibold"}>
                                    {_.get(follower, "user.displayName")}
                                </Text>
                                <Text color={"gray.400"} fontSize={"sm"}>
                                    {dayjs
                                        .unix(_.get(follower, "followedAt") / 1000)
                                        .format("YYYY-MM-DD HH:mm:ss")}
                                </Text>
                            </Box>
                        </Flex>
                        <Flex columnGap={4} fontSize={"lg"}>
                            <FAIcon cursor={"pointer"} group="solid" name={"comment"}/>
                            <FAIcon cursor={"pointer"} group="solid" name={"circle-info"}/>
                        </Flex>
                    </Flex>
                );
            })}
        </SimpleGrid>
    );
};


export default function CreatorFetchPage() {
    const params = useParams();
    const {id} = params;
    const [creator, setCreator] = useState(null);
    const {firestore} = useFirebase();
    const navigate = useNavigate();
    const toast = useToast();
    const [isDeleting, setIsDeleting] = useState(false);
    const {count, loading} = useVideoCountByCreatorId(id);

    useEffect(() => {
        if (!id) return;

        const docRef = doc(firestore, "creators", id);
        const unsubscribe = onSnapshot(docRef, {
            next: (docSnap) => {
                if (docSnap.exists()) {
                    setCreator({id: docSnap.id, ...docSnap.data()});
                }
            },
            includeMetadataChanges: true,
        });

        return () => unsubscribe();
    }, [id, firestore]);

    // when removing videos, reduce videos total by 1
    const reduceVideosTotal = useCallback(() => {
        const videosTotal = _.get(creator, "videosTotal", 0);
        setCreator({
            ...creator,
            videosTotal: videosTotal - 1,
        });
    }, [creator]);

    return (
        <MainLayout>
            <SimpleGrid paddingY={8}>
                <Flex
                    rowGap={4}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Flex width={"full"} alignItems={"center"} paddingX={8} columnGap={8}>
                        <Circle
                            size={24}
                            backgroundColor={"black"}
                            backgroundImage={
                                _.get(creator, "picture")
                                    ? `url('${getPublicDownloadUrl(_.get(creator, "picture"))}')`
                                    : null
                            }
                            backgroundSize={"cover"}
                            backgroundPosition={"center"}
                        />
                        <SimpleGrid
                            width={"full"}
                            borderRadius={"md"}
                            marginTop={4}
                            columns={3}
                            paddingY={4}
                        >
                            <Flex flexDirection={"column"} rowGap={2} alignItems={"center"}>
                                <Text fontSize={"xl"}>
                                    {_.get(creator, "followersTotal", 0)}
                                </Text>
                                <Text fontSize={"xs"}>追蹤數</Text>
                            </Flex>
                            <Flex flexDirection={"column"} rowGap={2} alignItems={"center"}>
                                <Box fontSize={"xl"} textAlign="center">
                                    {loading ? (
                                        <CircularProgress size="20px" isIndeterminate/>
                                    ) : (
                                        count
                                    )}
                                </Box>
                                <Text fontSize={"xs"}>上架影片數</Text>
                            </Flex>
                            <Flex flexDirection={"column"} rowGap={2} alignItems={"center"}>
                                <Text fontSize={"xl"}>
                                    {_.get(creator, "points.balance", 0)}
                                </Text>
                                <Text fontSize={"xs"}>鑽石數</Text>
                            </Flex>
                        </SimpleGrid>
                    </Flex>
                    <Flex width={"full"} paddingX={8}>
                        <Box>
                            <Text fontWeight={"bold"}>
                                {_.get(creator, "displayName", "未命名創作者")}
                            </Text>
                            <Text fontSize={"sm"}>
                                @{_.get(creator, "slug", "anonymouse")}
                            </Text>
                            <Text fontSize={"sm"} color="gray.400">
                                加入日期：
                                {dayjs(_.get(creator, "createdAt")).format("YYYY-MM-DD")}
                            </Text>
                            
                            <Button
                                colorScheme="red"
                                size="sm"
                                mt={2}
                                isLoading={isDeleting}
                                onClick={async () => {
                                    if (!window.confirm("確定要刪除此創作者嗎？此動作無法復原。")) {
                                        return;
                                    }

                                    setIsDeleting(true);
                                    try {
                                        await deleteCreator(firestore, id);
                                        toast({
                                            title: "創作者已刪除",
                                            status: "success",
                                            duration: 3000,
                                        });
                                        navigate("/creators");
                                    } catch (error) {
                                        console.error("Error deleting creator:", error);
                                        toast({
                                            title: "刪除失敗",
                                            description: error.message,
                                            status: "error",
                                            duration: 5000,
                                        });
                                    } finally {
                                        setIsDeleting(false);
                                    }
                                }}
                            >
                                刪除創作者
                            </Button>
                        </Box>
                        <Box>{_.get(creator, "description")}</Box>
                    </Flex>
                </Flex>
                <Tabs isLazy={true} marginTop={8}>
                    <TabList>
                        <Tab>影片</Tab>
                        <Tab>追蹤者</Tab>
                        <Tab>錢包紀錄</Tab>
                        <Tab>聊天室</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel padding={0}>
                            <VideosList id={id} onRemove={reduceVideosTotal}/>
                        </TabPanel>
                        <TabPanel>
                            <FollowersList id={id}/>
                        </TabPanel>
                        <TabPanel>
                            <CreatorWallet creator={creator}/>
                        </TabPanel>
                        <TabPanel p={0}>
                            <ChatsList creator={creator}/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </SimpleGrid>
        </MainLayout>
    );
}
