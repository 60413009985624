import {collection, deleteDoc, doc, getDocs, query, updateDoc, where} from "firebase/firestore";

export const deleteVideo = async (firestore, videoId, forceDelete = false) => {
    // Check for video unlockers
    const unlockersQuery = query(
        collection(firestore, "video-unlockers"),
        where("videoId", "==", videoId)
    );
    const unlockersSnap = await getDocs(unlockersQuery);

    const videoRef = doc(firestore, "videos", videoId);

    if (unlockersSnap.empty || forceDelete) {
        // Delete video if no unlockers or force delete is true
        await deleteDoc(videoRef);
    } else {
        // Set video offline if it has unlockers
        await updateDoc(videoRef, {
            status: 'OFFLINE'
        });
    }

    // Delete related collections
    const saversQuery = query(collection(firestore, "video-savers"), where("videoId", "==", videoId));
    const likersQuery = query(collection(firestore, "video-likers"), where("videoId", "==", videoId));

    const saversSnap = await getDocs(saversQuery);
    const likersSnap = await getDocs(likersQuery);

    // Delete all savers
    for (const doc of saversSnap.docs) {
        await deleteDoc(doc.ref);
    }

    // Delete all likers
    for (const doc of likersSnap.docs) {
        await deleteDoc(doc.ref);
    }
};
