import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from "./App";
import reportWebVitals from "./reportWebVitals";

// router
import { Route, BrowserRouter, Routes } from "react-router-dom";
// UI
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
// 多國語言套件
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// 多國語言檔案
import enLocale from "@resources/en.json";
import zhTWLocale from "@resources/zh-TW.json";

// firebase
import { FirebaseProvider } from "@context/firebase.context";
import { AppProvider } from "@context/app.context";

// routes
import GeneralRoutes from "@routes/general.routes";
import AuthRoutes from "@routes/auth.routes";
import { VideoSelectorProvider } from "context/video-selector.context";

// 多國語言設定
i18n
  // .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "zh-TW", // use et if detected lng is not available
    saveMissing: true, // send not translated keys to endpoint
    debug: true,
    whitelist: ["zh-TW", "en"],
    resources: {
      en: {
        translation: enLocale,
      },
      "zh-TW": {
        translation: zhTWLocale,
      },
    },
    detection: {
      order: ["querystring", "localStorage"],
      lookupQuerystring: "locale",
      lookupLocalStorage: "locale",
      caches: ["localStorage", "cookie"],
    },
    react: {
      useSuspense: false,
    },
  });

localStorage.setItem("chakra-ui-color-mode", "dark");

const theme = extendTheme({
  initialColorMode: "dark",
  useSystemColorMode: false,
  colors: {
    // gray: {
    //   100: "#bcbabb", // 在淺灰中加入一點橘紅色
    //   200: "#b0a8a9", // 輕微橘紅色調的中淺灰
    //   300: "#989697", // 標準灰帶一點橘紅色
    //   400: "#828081", // 中深灰帶橘紅色調
    //   500: "#666465", // 深灰帶微妙的橘紅色調
    //   600: "#504849", // 更深灰色帶橘紅色基底
    //   700: "#343233", // 接近黑色的深灰帶橘紅色調
    //   800: "#181617", // 更接近黑色的灰帶橘紅色調
    //   900: "#100809", // 極深灰色（幾乎是黑色）帶微妙的橘紅色基底
    // },
  },
  sizes: {
    100: "25rem",
    102: "25.5rem",
    104: "26rem",
    106: "26.5rem",
    108: "27rem",
    110: "27.5rem",
    112: "28rem",
    114: "28.5rem",
    116: "29rem",
    118: "29.5rem",
    120: "30rem",
    160: "40rem",
    180: "45rem",
    200: "50rem",
    240: "60rem",
    280: "70rem",
    320: "80rem",
  },
  components: {
    Link: {
      baseStyle: {
        _hover: {
          textDecoration: "none", // 這裡設置 hover 時不顯示文字裝飾
        },
      },
    },
  },
  shadows: {
    outline: "none",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <FirebaseProvider>
          <AppProvider>
            <VideoSelectorProvider>
              <Routes>
                <Route path="/auth/*" element={<AuthRoutes />} />
                <Route path="/*" element={<GeneralRoutes />} />
              </Routes>
            </VideoSelectorProvider>
          </AppProvider>
        </FirebaseProvider>
      </ChakraProvider>
    </BrowserRouter>
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
