import { Box, CircularProgress, Text } from "@chakra-ui/react";
import { useVideoCountByCreatorId } from "hooks/useVideoCountByCreatorId";

export default function VideoCount({ creatorId }) {
  const { count, loading } = useVideoCountByCreatorId(creatorId);

  return (
    <Box fontSize="2xl">
      {loading ? (
        <CircularProgress size="20px" isIndeterminate />
      ) : (
        <Text as="span">{count}</Text>
      )}
    </Box>
  );
}
