import {Avatar, Box, Center, Flex, HStack, Image, Text, VStack,} from "@chakra-ui/react";
import VideoPlayer from "./VideoPlayer";
import FAIcon from "./FAIcon";
import {doc, getDoc} from "firebase/firestore";
import {useEffect, useState} from "react";
import getPublicDownloadUrl from "libs/get-public-download-url";
import canonicalDate from "libs/canonical-date";
import {useFirebase} from "../context/firebase.context";

export default function ChatMessage({message, creator}) {
    const [user, setUser] = useState(null);
    const {firestore} = useFirebase();
    const [activeVideo, setActiveVideo] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
            if (message.sender.type !== "user") return;

            const docRef = doc(firestore, "users", message.sender.id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setUser({id: docSnap.id, ...docSnap.data()});
            }
        };

        fetchUser();
    }, [message.sender.id, message.sender.type, firestore]);

    return (
        <Flex
            key={message.id}
            gap={3}
            align="start"
            flexDirection={message.sender.type === "user" ? "row" : "row-reverse"}
        >
            <Avatar
                size="sm"
                src={
                    message.sender.type === "user"
                        ? getPublicDownloadUrl(user?.picture)
                        : getPublicDownloadUrl(creator?.picture)
                }
            />
            <Box
                bg={message.sender.type === "user" ? "gray.700" : "blue.500"}
                p={3}
                borderRadius="lg"
                maxW="70%"
            >
                {message.type === "text" && (
                    <VStack align="start" spacing={1}>
                        <Text>{message.text}</Text>
                    </VStack>
                )}
                {message.type === "gift" && (
                    <VStack align="start" spacing={1}>
                        <Text>送出禮物：{message.gift}</Text>
                        <Center
                            px={2}
                            py={1}
                            bg="blackAlpha.700"
                            borderRadius="md"
                            alignSelf="start"
                        >
                            <HStack spacing={1}>
                                <FAIcon name="diamond"/>
                                <Text>{message.income} 鑽石</Text>
                            </HStack>
                        </Center>
                    </VStack>
                )}
                {message.type === "video" && (
                    <VStack align="start" spacing={1}>
                        <Text>{message.text}</Text>
                        <Box
                            position="relative"
                            w="200px"
                            h="356px"
                            cursor="pointer"
                            onClick={() => setActiveVideo(message)}
                        >
                            <Image
                                src={getPublicDownloadUrl(message.thumbnail?.clear)}
                                objectFit="cover"
                                w="100%"
                                h="100%"
                                borderRadius="md"
                            />
                            <Center
                                position="absolute"
                                top={0}
                                right={0}
                                px={2}
                                py={1}
                                bg="blackAlpha.700"
                                borderRadius="md"
                            >
                                <HStack spacing={1}>
                                    <FAIcon name={message.isUnlocked ? "unlock" : "lock"}/>
                                    <Text>{message.price} 鑽石</Text>
                                </HStack>
                            </Center>
                        </Box>
                    </VStack>
                )}
                {message.type === "image" && (
                    <VStack align="start" spacing={1}>
                        <Text>{message.text}</Text>
                        <Box position="relative" w="200px" h="200px">
                            <Image
                                src={getPublicDownloadUrl(message.image?.clear)}
                                objectFit="cover"
                                w="100%"
                                h="100%"
                                borderRadius="md"
                            />
                            <Center
                                position="absolute"
                                top={0}
                                right={0}
                                px={2}
                                py={1}
                                bg="blackAlpha.700"
                                borderRadius="md"
                            >
                                <HStack spacing={1}>
                                    <FAIcon name={message.isUnlocked ? "unlock" : "lock"}/>
                                    <Text>{message.price} 鑽石</Text>
                                </HStack>
                            </Center>
                        </Box>
                    </VStack>
                )}
                {message.type === "audio" && (
                    <HStack spacing={2} align="center">
                        <audio
                            src={getPublicDownloadUrl(message.audio)}
                            controls
                            style={{
                                width: '140px',
                                height: '32px'
                            }}
                        />
                        {!message.isUnlocked && (
                            <HStack spacing={1}>
                                <FAIcon name="lock"/>
                                <Text>{message.price} 鑽石</Text>
                            </HStack>
                        )}
                    </HStack>
                )}
                <HStack fontSize="xs" color="gray.400" mt={1} spacing={2}>
                    <Text>{canonicalDate(message.createdAt)}</Text>
                    {message.sender.type === "user" && message.income > 0 && (
                        <Text>{message.income} 鑽石</Text>
                    )}
                </HStack>
            </Box>
            {activeVideo && (
                <Box
                    position="fixed"
                    top={0}
                    left={0}
                    height="100vh"
                    width="full"
                    zIndex={101}
                >
                    <VideoPlayer
                        autoPlay={true}
                        creatorId={creator?.id}
                        video={activeVideo}
                        streamUrl={getPublicDownloadUrl(activeVideo.video?.path)}
                        downloadUrl={getPublicDownloadUrl(activeVideo.video?.path)}
                        onClose={() => setActiveVideo(null)}
                        options={{
                            close: true,
                        }}
                    />
                </Box>
            )}
        </Flex>
    );
}
