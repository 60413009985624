import {AspectRatio, Box, Flex, SimpleGrid, Spinner, Text,} from "@chakra-ui/react";
import FAIcon from "@components/FAIcon";
import VideoPlayer from "components/VideoPlayer";
import useAPI from "hooks/api";
import _ from "lodash";
import {useCallback, useEffect, useRef, useState} from "react";
import {useFirebase} from "context/firebase.context";

export const VideosList = ({id, onRemove}) => {
    const {user} = useFirebase();
    const [nextCursor, setNextCursor] = useState(null);
    const [loadingVideos, setLoadingVideos] = useState(false);
    const bottomDetectorRef = useRef(null);
    const api = useAPI();
    const [creatorVideos, setCreatorVideos] = useState([]);
    const [activeVideo, setActiveVideo] = useState(null);

    const loadCreatorVideos = useCallback(
        async (next = null) => {
            if (loadingVideos) return;
            setLoadingVideos(true);
            const listCreatorVideosResult = await api.getCreatorVideos(id, {next});
            const nextPageVideos = _.get(listCreatorVideosResult, "data", []);
            if (nextPageVideos.length === 0) {
                setLoadingVideos(false);
                return;
            }
            setCreatorVideos([
                ...creatorVideos,
                ..._.get(listCreatorVideosResult, "data", []),
            ]);
            setNextCursor(_.get(listCreatorVideosResult, "paginator.next"));
            setLoadingVideos(false);
        },
        [api, id, setCreatorVideos, creatorVideos, loadingVideos],
    );

    const playVideo = useCallback((video) => {
        setActiveVideo(video);
    }, []);

    const removeVideoFromList = useCallback(
        (id) => () => {
            const clone = creatorVideos.slice();
            const index = clone.findIndex((video) => video.id === id);
            if (index !== -1) {
                clone.splice(index, 1);
                setCreatorVideos(clone);
                onRemove();
            }
        },
        [creatorVideos, onRemove],
    );

    useEffect(() => {
        if (user) {
            loadCreatorVideos();
        }
    }, [user]); // eslint-disable-line

    useEffect(() => {
        if (bottomDetectorRef.current) {
            const intersectionObserver = new IntersectionObserver((entries) => {
                if (entries[0].intersectionRatio >= 1) {
                    if (!nextCursor) return;
                    loadCreatorVideos(nextCursor);
                }
            });
            intersectionObserver.observe(bottomDetectorRef.current);
            return () => {
                intersectionObserver.disconnect();
            };
        }
    }, [bottomDetectorRef, nextCursor]); // eslint-disable-line

    return (
        <>
            <SimpleGrid columns={3} spacing={1}>
                {creatorVideos.map((video) => {
                    return (
                        <Box
                            cursor={"pointer"}
                            id={_.get(video, "id")}
                            key={_.get(video, "id")}
                            onClick={() => playVideo(video)}
                        >
                            <AspectRatio ratio={9 / 16}>
                                <Box
                                    position={"relative"}
                                    backgroundSize={"cover"}
                                    backgroundPosition={"center"}
                                    backgroundImage={
                                        _.get(video, "thumbnail", null)
                                            ? `url("${_.get(video, "thumbnail", null)}")`
                                            : null
                                    }
                                    backgroundColor={"black"}
                                >
                                    <SimpleGrid
                                        width={"full"}
                                        position={"absolute"}
                                        bottom={0}
                                        left={0}
                                        backgroundColor={"blackAlpha.600"}
                                        paddingY={1}
                                        paddingX={2}
                                    >
                                        <Flex alignItems={"center"} fontSize={"sm"}>
                                            <FAIcon name="gem" group="solid"></FAIcon>
                                            <Text marginLeft={1}>{_.get(video, "price", 0)}</Text>
                                        </Flex>
                                        <Flex alignItems={"center"} fontSize={"sm"}>
                                            <FAIcon name="heart" group="solid"></FAIcon>
                                            <Text marginLeft={1}>
                                                {_.get(video, "likedTotal", 0)}
                                            </Text>
                                        </Flex>
                                        <Flex alignItems={"center"} fontSize={"sm"}>
                                            {_.get(video, "status") === "encoding" && (
                                                <>
                                                    <FAIcon name="timer"></FAIcon>
                                                    <Text marginLeft={1}>編碼中</Text>
                                                </>
                                            )}
                                            {_.get(video, "status") === "review" && (
                                                <>
                                                    <FAIcon name="eye"></FAIcon>
                                                    <Text marginLeft={1}>等待審核</Text>
                                                </>
                                            )}
                                            {_.get(video, "status") === "reviewing" && (
                                                <>
                                                    <FAIcon name="pen"></FAIcon>
                                                    <Text marginLeft={1}>審核中</Text>
                                                </>
                                            )}
                                            {_.get(video, "status") === "released" && (
                                                <>
                                                    <FAIcon name="check" color={"green.200"}></FAIcon>
                                                    <Text color={"green.200"} marginLeft={1}>
                                                        已放行
                                                    </Text>
                                                </>
                                            )}
                                            {_.get(video, "status") === "rejected" && (
                                                <>
                                                    <FAIcon name="xmark" color={"red.200"}></FAIcon>
                                                    <Text color={"red.200"} marginLeft={1}>
                                                        已拒絕
                                                    </Text>
                                                </>
                                            )}
                                        </Flex>
                                    </SimpleGrid>
                                </Box>
                            </AspectRatio>
                        </Box>
                    );
                })}
            </SimpleGrid>
            {activeVideo && (
                <Box
                    position={"fixed"}
                    top={0}
                    left={0}
                    height={"100vh"}
                    width={"full"}
                    zIndex={101}
                >
                    <VideoPlayer
                        autoPlay={true}
                        creatorId={_.get(activeVideo, "creatorId", null)}
                        video={activeVideo}
                        streamUrl={_.get(activeVideo, "playbacks[0].streamUrl", null)}
                        downloadUrl={_.get(
                            activeVideo,
                            "playbacks[1].streamUrl",
                            _.get(activeVideo, "playbacks[0].streamUrl", null),
                        )}
                        onClose={() => setActiveVideo(null)}
                        onDelete={removeVideoFromList(activeVideo.id)}
                        options={{
                            close: true,
                            trash: _.get(activeVideo, "status", null) === "rejected",
                        }}
                    />
                </Box>
            )}
            <Box
                id="bottom-detector"
                height={"1px"}
                ref={bottomDetectorRef}
                width={"full"}
            />
            {loadingVideos && (
                <Flex padding={4} justifyContent={"center"} alignItems={"center"}>
                    <Spinner/>
                </Flex>
            )}
        </>
    );
};
