import { useFirebase } from "context/firebase.context";
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

export function useUser(userId) {
  const [user, setUser] = useState(null);
  const { firestore } = useFirebase();

  useEffect(() => {
    if (!userId) return;

    const docRef = doc(firestore, "users", userId);
    const unsubscribe = onSnapshot(
      docRef,
      {
        next: (docSnap) => {
          if (docSnap.exists()) {
            setUser({ id: docSnap.id, ...docSnap.data() });
          }
        },
        includeMetadataChanges: false,
      },
      {
        source: "cache",
      },
    );

    return () => unsubscribe();
  }, [userId, firestore]);

  return user;
}
