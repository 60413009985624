import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import canonicalDate from "libs/canonical-date";
import { useUser } from "hooks/useUser";
import getPublicDownloadUrl from "libs/get-public-download-url";

export default function ChatItem({ chat, creator, onClick }) {
  const user = useUser(chat.userId);
  const lastMessage = chat.lastMessage;

  return (
    <Flex
      p={3}
      borderBottomWidth={1}
      gap={3}
      align="center"
      cursor="pointer"
      _hover={{ bg: "gray.700" }}
      onClick={onClick}
    >
      <Avatar src={getPublicDownloadUrl(user?.picture)} />
      <Box flex={1}>
        <Text pb={1}>{user?.displayName || "Loading..."}</Text>
        <Text noOfLines={1}>
          {lastMessage.sender.type === "user"
            ? user?.displayName || "用戶"
            : creator?.displayName}
          ： {lastMessage?.text}
          {lastMessage.type !== "text" && lastMessage.text && " | "}
          {lastMessage.type === "gift" && "禮物"}
          {lastMessage.type === "video" && "影片"}
          {lastMessage.type === "audio" && "錄音"}
        </Text>
      </Box>
      <Text>{canonicalDate(lastMessage.createdAt)}</Text>
    </Flex>
  );
}
