import {Box, Card, GridItem, SimpleGrid, Text} from "@chakra-ui/react";
import MainLayout from "@layouts/main.layout";
import dayjs from "dayjs";
import {useEffect, useMemo, useState} from "react";
import {collection, onSnapshot, orderBy, query, where,} from "firebase/firestore";
import firebase from "../libs/firebase";
import RevenueChart from "../components/RevenueChart";

export default function AnalyticsPage() {
    const [deposits, setDeposits] = useState([]);
    const [vipCharges, setVipCharges] = useState([]);
    const [todayVipRevenue, setTodayVipRevenue] = useState(0);

    const [todayDepositRevenue, setTodayDepositRevenue] = useState(0);

    const [videos, setVideos] = useState([]);
    const [todayVideoUploads, setTodayVideoUploads] = useState(0);
    const [activeUsers, setActiveUsers] = useState({
        dau: 0,
        wau: 0,
        current: 0,
    });

    const fetchData = (
        collectionName,
        queryConstraints,
        setState,
        transform = (data) => data,
    ) => {
        const ref = collection(firebase.firestore, collectionName);
        const q = query(ref, ...queryConstraints);
        return onSnapshot(q, (querySnapshot) => {
            const data = querySnapshot.docs.map((doc) => doc.data());
            const transformedData = Array.isArray(transform(data))
                ? transform(data)
                : [];
            setState(transformedData);
        });
    };

    useEffect(() => {
        const start = dayjs().subtract(15, "day").startOf("day").unix() * 1000;

        const unsubscribeVip = fetchData(
            "vip-charges",
            [where("createdAt", ">=", start)],
            setVipCharges,
        );
        const unsubscribeDeposit = fetchData(
            "deposits",
            [where("status", "==", "FINISHED"), where("createdAt", ">=", start)],
            setDeposits,
        );
        const unsubscribeVideo = fetchData(
            "videos",
            [where("createdAt", ">=", start)],
            setVideos,
        );

        // Subscribe to active users
        const usersRef = collection(firebase.firestore, "users");
        const sevenDaysAgo = dayjs().subtract(7, "days").toDate();
        const q = query(
            usersRef,
            where("lastActiveAt", ">=", sevenDaysAgo),
            orderBy("lastActiveAt", "desc"),
        );
        const unsubscribeUsers = onSnapshot(q, (snapshot) => {
            const now = dayjs();
            const activeStats = snapshot.docs.reduce(
                (acc, doc) => {
                    const userData = doc.data();
                    if (!userData.lastActiveAt) return acc;

                    const lastActiveAt = dayjs(userData.lastActiveAt.toDate());
                    const dayDiff = now.diff(lastActiveAt, "day");
                    const minuteDiff = now.diff(lastActiveAt, "minute");

                    return {
                        dau: acc.dau + (dayDiff === 0 ? 1 : 0),
                        wau: acc.wau + (dayDiff <= 7 ? 1 : 0),
                        current: acc.current + (minuteDiff <= 5 ? 1 : 0),
                    };
                },
                {dau: 0, wau: 0, current: 0},
            );

            setActiveUsers(activeStats);
        });

        return () => {
            unsubscribeVip();
            unsubscribeDeposit();
            unsubscribeVideo();
            unsubscribeUsers();
        };
    }, []);

    const formatCurrency = (amount) => {
        return amount.toLocaleString("en-US", {
            style: "currency",
            maximumFractionDigits: 0,
            currency: "USD",
        });
    };

    const calculateRevenue = (deposits, day) =>
        deposits
            .flat()
            .reduce(
                (acc, cur) =>
                    dayjs(cur.createdAt).isSame(day, "day") ? acc + cur.price : acc,
                0,
            );

    useEffect(() => {
        const today = dayjs().startOf("day");
        setTodayDepositRevenue(
            deposits.flat().reduce((acc, cur) => {
                if (dayjs(cur.createdAt).isSame(today, "day")) {
                    return acc + cur.price;
                }
                return acc;
            }, 0),
        );

        setTodayVideoUploads(
            videos.flat().reduce((acc, cur) => {
                if (dayjs(cur.createdAt).isSame(today, "day")) {
                    return acc + 1;
                }
                return acc;
            }, 0),
        );
        setTodayVipRevenue(
            vipCharges.reduce((acc, cur) => {
                if (dayjs(cur.createdAt).isSame(today, "day")) {
                    return acc + (cur.plan?.price || 0);
                }
                return acc;
            }, 0),
        );
    }, [deposits, videos, vipCharges]);

    const revenueData = useMemo(() => {
        const depositData = deposits.flat().map((deposit) => ({
            name: `$${deposit.price}`,
            price: deposit.price,
            date: dayjs(deposit.createdAt).format("YYYY-MM-DD"),
        }));
        const vipData = vipCharges.map((charge) => ({
            name: `VIP: $${charge.plan?.price}`,
            price: charge.plan?.price || 0,
            date: dayjs(charge.createdAt).format("YYYY-MM-DD"),
        }));

        // 根據價格排序所有數據
        const allData = [...depositData, ...vipData];
        return allData.sort((a, b) => a.price - b.price);
    }, [deposits, vipCharges]);

    const dailyRevenue = useMemo(() => {
        const today = dayjs().format("YYYY-MM-DD");
        return revenueData.reduce((acc, cur) => {
            if (cur.date === today) {
                return acc + cur.price;
            }
            return acc;
        }, 0);
    }, [revenueData]);

    const yesterdayRevenue = useMemo(() => {
        if (!deposits) return 0;
        const yesterday = dayjs().subtract(1, "day").startOf("day");
        return calculateRevenue(deposits, yesterday);
    }, [deposits]);

    const revenueGrowth = yesterdayRevenue
        ? (dailyRevenue - yesterdayRevenue) / yesterdayRevenue
        : 1;

    return (
        <MainLayout>
            <Box maxW={1200} mx="auto" mt={8} p={4}>
                <SimpleGrid columns={{base: 1, md: 3}} spacing={4}>
                    <Card p={4} textAlign="center">
                        <Text my={2} fontWeight="semibold" color="gray.400">
                            本日營收
                        </Text>
                        <Text fontSize="4xl" fontWeight="semibold" fontFamily="Outfit">
                            {formatCurrency(dailyRevenue)}
                        </Text>
                        <Text
                            color={revenueGrowth > 0 ? "green.600" : "red.600"}
                            fontWeight="bold"
                        >
                            {revenueGrowth >= 0 && "+"}
                            {(revenueGrowth * 100).toFixed(2)}%
                        </Text>
                    </Card>
                    <Card p={4} textAlign="center">
                        <Text my={2} fontWeight="semibold" color="gray.400">
                            本日VIP方案收入
                        </Text>
                        <Text fontSize="4xl" fontWeight="semibold" fontFamily="Outfit">
                            {formatCurrency(todayVipRevenue)}
                        </Text>
                    </Card>
                    <Card p={4} textAlign="center">
                        <Text my={2} fontWeight="semibold" color="gray.400">
                            本日儲值收入
                        </Text>
                        <Text fontSize="4xl" fontWeight="semibold" fontFamily="Outfit">
                            {formatCurrency(todayDepositRevenue)}
                        </Text>
                    </Card>

                    {/*<Card p={4} textAlign="center">*/}
                    {/*  <Text my={2} fontWeight="semibold" color="gray.400">*/}
                    {/*    日活躍用戶 (DAU)*/}
                    {/*  </Text>*/}
                    {/*  <Text fontSize="4xl" fontWeight="semibold" fontFamily="Outfit">*/}
                    {/*    {activeUsers.dau}*/}
                    {/*  </Text>*/}
                    {/*</Card>*/}
                    {/*<Card p={4} textAlign="center">*/}
                    {/*  <Text my={2} fontWeight="semibold" color="gray.400">*/}
                    {/*    週活躍用戶 (WAU)*/}
                    {/*  </Text>*/}
                    {/*  <Text fontSize="4xl" fontWeight="semibold" fontFamily="Outfit">*/}
                    {/*    {activeUsers.wau}*/}
                    {/*  </Text>*/}
                    {/*</Card>*/}
                    <Card p={4} textAlign="center">
                        <Text my={2} fontWeight="semibold" color="gray.400">
                            當前在線用戶
                        </Text>
                        <Text fontSize="4xl" fontWeight="semibold" fontFamily="Outfit">
                            {activeUsers.current}
                        </Text>
                    </Card>
                    <Card p={4} textAlign="center">
                        <Text my={2} fontWeight="semibold" color="gray.400">
                            本日上傳影片數
                        </Text>
                        <Text fontSize="4xl" fontWeight="semibold" fontFamily="Outfit">
                            {todayVideoUploads}
                        </Text>
                    </Card>

                    <GridItem colSpan={{base: 1, md: 3}}>
                        <Card p={4} textAlign="center">
                            <Text my={2} fontWeight="semibold" color="gray.400">
                                15 日營收走勢圖
                            </Text>
                            <RevenueChart source={revenueData}/>
                        </Card>
                    </GridItem>
                </SimpleGrid>
            </Box>
        </MainLayout>
    );
}
