import {useEffect, useState} from "react";
import {useFirebase} from "context/firebase.context";
import {collection, getCountFromServer, query, where} from "firebase/firestore";

export const useVideoCountByCreatorId = (creatorId) => {
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const {firestore} = useFirebase();

    useEffect(() => {
        const fetchCount = async () => {
            if (!creatorId) {
                setLoading(false);
                return;
            }

            try {
                const q = query(
                    collection(firestore, "videos"),
                    where("creatorId", "==", creatorId),
                    where("status", "==", "released"),
                );

                const snapshot = await getCountFromServer(q);
                setCount(snapshot.data().count);
            } catch (error) {
                console.error("Error fetching video count:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCount();
    }, [creatorId, firestore]);

    return {count, loading};
};
