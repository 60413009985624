import {
  Alert,
  AlertIcon,
  AspectRatio,
  Badge,
  Box,
  Button,
  Center,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import MainLayout from "layouts/main.layout";
import { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import VideoPlayer from "components/VideoPlayer";
import useAPI from "hooks/api";

export default function ReviewVideoPage() {
  const toast = useToast();
  const api = useAPI();

  const {
    isOpen: isAcceptOpen,
    onOpen: onAcceptOpen,
    onClose: onAcceptClose,
  } = useDisclosure();
  const {
    isOpen: isRejectOpen,
    onOpen: onRejectOpen,
    onClose: onRejectClose,
  } = useDisclosure();
  const {
    isOpen: isThumbnailOpen,
    onOpen: onThumbnailOpen,
    onClose: onThumbnailClose,
  } = useDisclosure();

  const [video, setVideo] = useState(null);
  const [count, setCount] = useState(null);
  const [forceBlur, setForceBlur] = useState(false);
  const [internalTags, setInternalTags] = useState([]);
  const internalTagInputRef = useRef(null);
  const reasonInputRef = useRef(null);

  const hasCount = !isNaN(count) && count != null;

  const onClaimReviewVideo = useCallback(async () => {
    const { data: video } = await api.claimVideoToReview();
    if (!video) {
      toast({
        title: "沒有新的需要被審核的影片",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    localStorage.setItem("reviewing-video-id", _.get(video, "id"));
    setVideo(video);
    setInternalTags(_.get(video, "internalTags", []));
  }, [api, toast]);

  const loadReviewingVideo = useCallback(async () => {
    // try to load reviewing-video-id
    const reviewingVideoId = localStorage.getItem("reviewing-video-id");
    if (reviewingVideoId) {
      const video = await api.getVideo(reviewingVideoId);

      if (_.get(video, "status") !== "reviewing") return;
      setVideo(video);
      setInternalTags(_.get(video, "internalTags", []));
    }
  }, [api]);

  const getReviewPendingVideosCount = useCallback(async () => {
    const data = await api.getReviewPendingVideosCount();
    let countData = parseInt(data?.count, 10);
    if (isNaN(countData)) countData = null;
    setCount(countData);
  }, [api]);

  const addInternalTag = useCallback(() => {
    if (internalTagInputRef.current) {
      const tag = (internalTagInputRef.current.value || "").trim();
      if (!tag) return;
      const newTagsList = [...internalTags, tag];
      setInternalTags(_.uniq(newTagsList));
      internalTagInputRef.current.value = "";
    }
  }, [internalTagInputRef, internalTags]);

  const removeInternalTag = useCallback(
    (value) => {
      const index = internalTags.indexOf(value);
      if (index >= 0) {
        const _internalTags = [...internalTags];
        _internalTags.splice(index, 1);
        setInternalTags([..._internalTags]);
      }
    },
    [internalTags],
  );

  const submitAccept = useCallback(async () => {
    await api.reviewVideo(_.get(video, "id"), {
      status: "released",
      forceBlur,
      internalTags,
    });

    toast({
      title: "已經放行此影片",
      status: "success",
      duration: 2000,
      isClosable: true,
      position: "top",
    });
    getReviewPendingVideosCount();
    setVideo(null);
    setForceBlur(false);
    onRejectClose();
    onAcceptClose();
  }, [
    api,
    video,
    forceBlur,
    internalTags,
    toast,
    getReviewPendingVideosCount,
    onRejectClose,
    onAcceptClose,
  ]);

  const submitReject = useCallback(async () => {
    await api.reviewVideo(_.get(video, "id"), {
      status: "rejected",
      reason: reasonInputRef.current.value,
      internalTags: [],
    });
    toast({
      title: "已經拒絕此影片",
      status: "success",
      duration: 2000,
      isClosable: true,
      position: "top",
    });
    setVideo(null);
    getReviewPendingVideosCount();
    onRejectClose();
    onAcceptClose();
  }, [
    api,
    video,
    toast,
    getReviewPendingVideosCount,
    onRejectClose,
    onAcceptClose,
  ]);

  useEffect(() => {
    getReviewPendingVideosCount();
    loadReviewingVideo();
  }, [loadReviewingVideo, getReviewPendingVideosCount]);

  return (
    <MainLayout
      display="flex"
      alignItems="center"
      flexDirection="column"
      height="100%"
    >
      <Box paddingY={4} flex={1}>
        {_.get(video, "playbacks[0].streamUrl", null) ? (
          <Flex
            justifyContent="center"
            height="full"
            width={120}
            position="relative"
          >
            <Box position="absolute" top={4} right={0} width={28} zIndex={10}>
              <Button colorScheme="blue" onClick={onThumbnailOpen}>
                顯示縮圖
              </Button>
            </Box>
            <AspectRatio ratio={9 / 16} width="full" borderRadius="md">
              <VideoPlayer
                creatorId={_.get(video, "creatorId", null)}
                streamUrl={_.get(video, "playbacks[0].streamUrl", null)}
              />
            </AspectRatio>
          </Flex>
        ) : (
          <Center p={4}>
            <Alert status="info" width={{ base: 80, md: 360 }}>
              <AlertIcon />
              {count == null && "正在獲取影片列表..."}
              {hasCount &&
                (count === 0
                  ? "目前沒有需要審核的影片"
                  : `尚有 ${count} 支影片等待審核`)}
            </Alert>
          </Center>
        )}
      </Box>
      <Box width={"full"} height={16} zIndex={100} backgroundColor={"gray.800"}>
        <Box
          position={"relative"}
          margin={"0 auto"}
          maxWidth={120}
          width={"full"}
        >
          <Flex
            position={"absolute"}
            width={"full"}
            height={16}
            justifyContent={"space-between"}
            alignItems={"center"}
            backgroundColor={"gray.700"}
            px={4}
            gap={4}
          >
            {video ? (
              <>
                <Button colorScheme={"red"} onClick={onRejectOpen} flex={1}>
                  拒絕
                </Button>
                <Button colorScheme={"blue"} onClick={onAcceptOpen} flex={1}>
                  放行
                </Button>
                <Checkbox
                  isChecked={forceBlur}
                  onChange={(e) => setForceBlur(e.target.checked)}
                >
                  模糊封面
                </Checkbox>
              </>
            ) : (
              <Button
                colorScheme={"blue"}
                onClick={onClaimReviewVideo}
                flex={1}
              >
                審核下一支影片
              </Button>
            )}
          </Flex>
        </Box>
      </Box>
      <Drawer isOpen={isAcceptOpen} placement="bottom" onClose={onAcceptClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>放行影片</DrawerHeader>

          <DrawerBody>
            <SimpleGrid spacing={4}>
              <FormControl>
                <FormLabel>影片說明</FormLabel>
                <Text>{_.get(video, "description", 0)}</Text>
              </FormControl>
              <FormControl>
                <FormLabel>價格</FormLabel>
                <Text>{_.get(video, "price", 0)}</Text>
              </FormControl>
              <FormControl>
                <FormLabel>標籤</FormLabel>
                <Flex>
                  {_.get(video, "tags", []).map((tag) => (
                    <Badge
                      key={tag}
                      textTransform={"none"}
                      colorScheme={"blue"}
                      fontSize={"lg"}
                    >
                      {tag}
                    </Badge>
                  ))}
                </Flex>
              </FormControl>
              <FormControl>
                <FormLabel>內部標籤</FormLabel>
                <Box>
                  <Flex>
                    <Input ref={internalTagInputRef} />
                    <Button marginLeft={2} onClick={addInternalTag}>
                      新增
                    </Button>
                  </Flex>
                  <Flex marginTop={4}>
                    {internalTags.map((tag) => (
                      <Badge
                        key={tag}
                        onClick={() => removeInternalTag(tag)}
                        marginRight={2}
                        textTransform={"none"}
                        colorScheme={"blue"}
                        fontSize={"lg"}
                      >
                        {tag}
                      </Badge>
                    ))}
                  </Flex>
                </Box>
              </FormControl>
            </SimpleGrid>
          </DrawerBody>

          <DrawerFooter>
            <Button onClick={onAcceptClose}>取消</Button>
            <Button onClick={submitAccept} marginLeft={2} colorScheme="blue">
              送出
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Drawer isOpen={isRejectOpen} placement="bottom" onClose={onRejectClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>拒絕影片</DrawerHeader>

          <DrawerBody>
            <SimpleGrid spacing={4}>
              <FormControl>
                <FormLabel>拒絕原因</FormLabel>
                <Textarea ref={reasonInputRef}></Textarea>
              </FormControl>
            </SimpleGrid>
          </DrawerBody>

          <DrawerFooter>
            <Button onClick={onRejectClose}>取消</Button>
            <Button onClick={submitReject} marginLeft={2} colorScheme="blue">
              送出
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Modal isOpen={isThumbnailOpen} onClose={onThumbnailClose}>
        <ModalOverlay />
        <ModalContent>
          <Image src={video?.thumbnail} />
        </ModalContent>
      </Modal>
    </MainLayout>
  );
}
