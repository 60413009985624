import {
  Avatar,
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import MainLayout from "@layouts/main.layout";
import FAIcon from "components/FAIcon";
import dayjs from "dayjs";
import useAPI from "hooks/api";
import getPublicDownloadUrl from "libs/get-public-download-url";
import _ from "lodash";
import {useCallback, useEffect, useState} from "react";

const PassedStatus = ({field}) =>
    field ? (
        <FAIcon name="circle-check" group="solid" color="green.300"/>
    ) : (
        <FAIcon name="circle-x" group="solid" color="red.500"/>
    );

const VIP_PLAN_NAME_MAPPING = {
    season: "優惠季訂方案",
    "half-year": "奢華雙季方案",
    month: "小資月訂方案",
};

export default function UserSearchPage() {
    const api = useAPI();
    const [input, setInput] = useState(new URLSearchParams(window.location.search).get('id') || "");
    const [user, setUser] = useState(null);
    const [value, setValue] = useState(0);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [searching, setSearching] = useState(false);
    const toast = useToast();
    const vip = user?.vip;

    const cancelVIP = useCallback(async () => {
        if (!user) return;
        await api.cancelVIP(user.id);
        toast({title: "已取消用戶 VIP", status: "success"});
        const updated = await api.getUser(input);
        setUser(updated);
    }, [api, input, toast, user]);

    const searchUser = useCallback(async (forceSearch = false) => {
        setSearching(true);
        const user = await api.getUser(input);
        if (user && user.id) {
            setUser(user);
            toast({title: "搜尋成功", status: "success"});
        } else {
            toast({title: "無搜尋結果", status: "error"});
        }
        setSearching(false);
    }, [api, input, toast]);

    // Initial search if ID is provided in URL
    useEffect(() => {
        if (input) {
            searchUser();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const confirmDeposit = useCallback(async () => {
        if (!user) return;
        const result = await api.deposit(user.id, value);
        if (result.points) {
            toast({title: "儲值成功", status: "success"});
            const clone = _.cloneDeep(user);
            clone.points.balance += result.points;
            setUser(clone);
            onClose();
        } else {
            toast({title: "操作失敗", status: "error"});
        }
    }, [api, user, value, onClose, toast]);

    return (
        <MainLayout>
            <VStack mx="auto" width={80} align="stretch" mt={4}>
                <Input
                    placeholder="使用者 ID/名稱/電子郵件/手機號碼"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.code === "Enter") {
                            searchUser();
                        }
                    }}
                />
                <Button
                    colorScheme="blue"
                    onClick={searchUser}
                    isLoading={searching}
                    isDisabled={!input}
                >
                    搜尋
                </Button>
            </VStack>
            {user && (
                <Box
                    width={{base: 80, md: 240}}
                    mx="auto"
                    boxShadow="dark-lg"
                    bg="gray.700"
                    mt={4}
                    rounded="md"
                    p={{base: 4, md: 8}}
                >
                    <VStack align="center">
                        <Avatar src={getPublicDownloadUrl(user.picture)} size="2xl"/>
                        <Text fontSize="lg">{user.displayName}</Text>
                    </VStack>

                    <Flex mx="auto" maxW={120} my={3} gap={2}>
                        <InputGroup>
                            <InputLeftAddon>手動儲值</InputLeftAddon>
                            <Input
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                type="number"
                            />
                        </InputGroup>
                        <Button onClick={onOpen}>確認</Button>
                    </Flex>

                    <Tabs mt={6} isFitted>
                        <TabList>
                            <Tab>個人資訊</Tab>
                            <Tab>儲值紀錄</Tab>
                            <Tab>鑽石異動紀錄</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel px={0}>
                                <VStack align="start">
                                    <Text>使用者 ID： {user.id}</Text>
                                    <Flex gap={2}>
                                        {user.disabled ? (
                                            <Button
                                                colorScheme="green"
                                                size="sm"
                                                onClick={async () => {
                                                    await api.enableUser(user.id);
                                                    toast({title: "已啟用用戶", status: "success"});
                                                    const updated = await api.getUserStatus(user.id);
                                                    setUser({...user, ...updated});
                                                }}
                                            >
                                                啟用用戶
                                            </Button>
                                        ) : (
                                            <Button
                                                colorScheme="red"
                                                size="sm"
                                                onClick={async () => {
                                                    await api.disableUser(user.id);
                                                    toast({title: "已停用用戶", status: "success"});
                                                    const updated = await api.getUserStatus(user.id);
                                                    setUser({...user, ...updated});
                                                }}
                                            >
                                                停用用戶
                                            </Button>
                                        )}
                                    </Flex>
                                    <Text>帳號狀態： {user.disabled ? '已停用' : '使用中'}</Text>
                                    <Text>
                                        KYC 狀態： <PassedStatus field={user.kyc}/>
                                    </Text>
                                    <Flex gap={2} align="center">
                                        VIP：
                                        <PassedStatus field={user.isVip}/>
                                        {user.isVip && (
                                            <>
                                                <Box
                                                    bg="yellow.500"
                                                    color="white"
                                                    px={3}
                                                    py={1}
                                                    rounded="lg"
                                                    fontWeight="semibold"
                                                >
                                                    {VIP_PLAN_NAME_MAPPING[vip.plan.id]}:{" "}
                                                    {dayjs(vip.expiredAt).format("MM/DD/YYYY")} 到期
                                                </Box>
                                                <Button
                                                    onClick={cancelVIP}
                                                    size="sm"
                                                    isDisabled={vip.status === "CANCELED"}
                                                >
                                                    {vip.status === "CANCELED" ? "已取消" : "取消"}
                                                </Button>
                                            </>
                                        )}
                                    </Flex>
                                    <Flex gap={1} align="center">
                                        <Text>信箱： {user.email}</Text>
                                        <PassedStatus field={user.emailVerified}/>
                                    </Flex>
                                    <Text>手機： {user.phoneNumber}</Text>
                                    <Text>鑽石餘額： {user.points?.balance || 0}</Text>
                                    <Text>
                                        鑽石總消費： {user.summary?.totalPointsSpent || 0}
                                    </Text>
                                </VStack>
                            </TabPanel>
                            <TabPanel px={0}>
                                <TableContainer>
                                    <Table variant="striped" size={{base: "sm", md: "lg"}}>
                                        <Thead>
                                            <Tr>
                                                <Th>金額</Th>
                                                <Th>鑽石異動</Th>
                                                <Th>信用卡末四碼</Th>
                                                <Th>狀態</Th>
                                                <Th>時間</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {user.deposits?.map(
                                                ({
                                                     id,
                                                     price,
                                                     creditCard,
                                                     points,
                                                     status,
                                                     createdAt,
                                                 }) => (
                                                    <Tr key={id}>
                                                        <Td>{price}</Td>
                                                        <Td>{points}</Td>
                                                        <Td>{creditCard.lastFour}</Td>
                                                        <Td>{status}</Td>
                                                        <Td>
                                                            {dayjs(createdAt).format("YY/MM/DD hh:mm:ss")}
                                                        </Td>
                                                    </Tr>
                                                ),
                                            )}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>
                            <TabPanel px={0}>
                                <TableContainer>
                                    <Table variant="striped" size={{base: "sm", md: "lg"}}>
                                        <Thead>
                                            <Tr>
                                                <Th>鑽石</Th>
                                                <Th>類型</Th>
                                                <Th>時間</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {user.transactions?.map(
                                                ({id, points, type, createdAt}) => (
                                                    <Tr key={id}>
                                                        <Td>{points}</Td>
                                                        <Td>{type}</Td>
                                                        <Td>
                                                            {dayjs(createdAt).format("YY/MM/DD hh:mm:ss")}
                                                        </Td>
                                                    </Tr>
                                                ),
                                            )}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                    <Modal isOpen={isOpen} onClose={onClose} isCentered>
                        <ModalOverlay/>
                        <ModalContent>
                            <ModalHeader>確認儲值</ModalHeader>
                            <ModalCloseButton/>
                            <ModalBody>
                                <Text>
                                    確認儲值 {value} 鑽石給予 {user.displayName}?
                                </Text>
                            </ModalBody>
                            <ModalFooter display="flex" justifyContent="center" gap={2}>
                                <Button colorScheme="gray" onClick={onClose}>
                                    取消
                                </Button>
                                <Button colorScheme="blue" onClick={confirmDeposit}>
                                    確認
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </Box>
            )}
        </MainLayout>
    );
}
