import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  SimpleGrid,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useVideoSelector } from "context/video-selector.context";
import { useCallback, useEffect, useState } from "react";
import FAIcon from "./FAIcon";
import useAPI from "hooks/api";

const VideoSelector = ({ onSelect }) => {
  const api = useAPI();
  const { videos, load, loaded } = useVideoSelector();
  const toast = useToast();
  const [id, setId] = useState("");
  const [searchResult, setSearchResult] = useState();

  const search = useCallback(async () => {
    if (!id) return;
    const response = await api.getVideo(id);
    if (!response || response.error) {
      toast({
        title: "無此影片",
        description: response.error,
        status: "error",
      });
      return;
    }
    setSearchResult(response);
  }, [api, id, toast]);

  const reset = useCallback(() => {
    setId("");
    setSearchResult(null);
  }, []);

  const select = useCallback(
    (video) => () => {
      onSelect(video);
      reset();
    },
    [onSelect, reset],
  );

  useEffect(() => {
    if (videos.length === 0) {
      load();
    }
  }, [videos, load]);

  return (
    <Box>
      <Box px={1} mt={2}>
        <InputGroup>
          <InputLeftElement>
            <FAIcon name="search" />
          </InputLeftElement>
          <Input
            placeholder="搜尋影片"
            value={id}
            onChange={(e) => setId(e.target.value)}
          />
          <InputRightAddon role="button" onClick={search}>
            搜尋
          </InputRightAddon>
        </InputGroup>
        {searchResult && (
          <Flex justify="flex-end" mt={3}>
            <Button onClick={reset}>清除搜尋結果</Button>
          </Flex>
        )}
      </Box>
      {searchResult ? (
        <Box p={8}>
          <Box
            key={searchResult.id}
            position="relative"
            role="button"
            onClick={select(searchResult)}
          >
            <AspectRatio ratio={9 / 16}>
              <Image src={searchResult.thumbnail} />
            </AspectRatio>
            <Box
              position="absolute"
              bottom={0}
              py={1}
              px={2}
              fontWeight="bold"
              bg="rgba(0,0,0,.5)"
              width="100%"
            >
              {searchResult.price} 鑽
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={1} my={3}>
            {videos?.map((video) => (
              <Box
                key={video.id}
                position="relative"
                role="button"
                onClick={select(video)}
              >
                <AspectRatio ratio={9 / 16}>
                  <Image src={video.thumbnail} />
                </AspectRatio>
                <Box
                  position="absolute"
                  bottom={0}
                  py={1}
                  px={2}
                  fontWeight="bold"
                  bg="rgba(0,0,0,.5)"
                  width="100%"
                >
                  {video.price} 鑽
                </Box>
              </Box>
            ))}
          </SimpleGrid>
          {!loaded && (
            <VStack pb={2} align="stretch" px={2}>
              <Button align="center" onClick={load}>
                讀取更多
              </Button>
            </VStack>
          )}
        </>
      )}
    </Box>
  );
};

export default VideoSelector;
