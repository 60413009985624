import {
    Box,
    Center,
    CircularProgress,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    VStack,
} from "@chakra-ui/react";
import ChatItem from "components/ChatItem";
import ChatMessage from "components/ChatMessage";
import {useFirebase} from "context/firebase.context";
import {collection, getDocs, limit, onSnapshot, orderBy, query, startAfter, where} from "firebase/firestore";
import {useCallback, useEffect, useRef, useState} from "react";

export const ChatsList = ({creator}) => {
    const [chats, setChats] = useState(null);
    const [lastChat, setLastChat] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const {firestore} = useFirebase();
    const [selectedChat, setSelectedChat] = useState(null);
    const [messages, setMessages] = useState(null);
    const [oldestMessageTimestamp, setOldestMessageTimestamp] = useState(null);
    const [loadingMoreMessages, setLoadingMoreMessages] = useState(false);
    const [hasMoreMessages, setHasMoreMessages] = useState(true);
    const chatListRef = useRef(null);
    const messagesContainerRef = useRef(null);

    const loadMoreChats = useCallback(async () => {
        if (!creator?.id || loading || !hasMore) return;

        setLoading(true);
        let q = query(
            collection(firestore, "chatrooms"),
            where("creatorId", "==", creator.id),
            orderBy("lastMessagedAt", "desc"),
            limit(25),
        );

        if (lastChat) {
            q = query(
                collection(firestore, "chatrooms"),
                where("creatorId", "==", creator.id),
                orderBy("lastMessagedAt", "desc"),
                startAfter(lastChat),
                limit(25),
            );
        }

        const snapshot = await getDocs(q);
        const chatData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        setChats((prev) => (prev ? [...prev, ...chatData] : chatData));
        setLastChat(snapshot.docs[snapshot.docs.length - 1]);
        setHasMore(snapshot.docs.length === 25);
        setLoading(false);
    }, [creator?.id, firestore, lastChat, loading, hasMore]);

    useEffect(() => {
        if (!creator?.id) return;
        setChats(null);
        setLastChat(null);
        setHasMore(true);
        loadMoreChats();
    }, [creator?.id]); // eslint-disable-line

    useEffect(() => {
        if (chatListRef.current) {
            const observer = new IntersectionObserver(
                (entries) => {
                    if (entries[0].isIntersecting && hasMore && !loading) {
                        loadMoreChats();
                    }
                },
                {threshold: 0.5},
            );

            observer.observe(chatListRef.current);
            return () => observer.disconnect();
        }
    }, [loadMoreChats, hasMore, loading]);

    return (
        <VStack align="stretch">
            {chats === null ? (
                <Center mt={6}>
                    <CircularProgress isIndeterminate/>
                </Center>
            ) : (
                <>
                    {chats.map((chat) => (
                        <ChatItem
                            key={chat.id}
                            chat={chat}
                            creator={creator}
                            onClick={() => {
                                setSelectedChat(chat.id);
                                // Reset states when opening new chat
                                setMessages(null);
                                setOldestMessageTimestamp(null);
                                setHasMoreMessages(true);

                                // Initial query for latest 100 messages
                                const q = query(
                                    collection(firestore, "chatroom-messages"),
                                    where("chatroomId", "==", chat.id),
                                    orderBy("createdAt", "desc"),
                                    limit(30)
                                );

                                onSnapshot(q, {
                                    next: (snapshot) => {
                                        console.log('Initial messages query snapshot:', snapshot.size);
                                        const messagesData = snapshot.docs.map((doc) => ({
                                            id: doc.id,
                                            ...doc.data(),
                                            formattedTime: doc.data().createdAt && typeof doc.data().createdAt.toDate === 'function' 
                                                ? doc.data().createdAt.toDate().toLocaleString('zh-TW', {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: false
                                            }) 
                                            : ''
                                        }));
                                        console.log('Processed messages data:', messagesData);
                                        if (messagesData.length > 0) {
                                            console.log('Oldest message timestamp:', messagesData[messagesData.length - 1].createdAt);
                                            setOldestMessageTimestamp(messagesData[messagesData.length - 1].createdAt);
                                        }
                                        setMessages(messagesData.reverse());
                                    },
                                });
                            }}
                        />
                    ))}
                    <Box ref={chatListRef} h="20px"/>
                    {loading && (
                        <Center mt={2} mb={4}>
                            <CircularProgress size="20px" isIndeterminate/>
                        </Center>
                    )}
                </>
            )}

            <Modal
                isOpen={selectedChat !== null}
                onClose={() => {
                    setSelectedChat(null);
                    setMessages(null);
                }}
                size="xl"
            >
                <ModalOverlay/>
                <ModalContent bg="gray.800">
                    <ModalHeader>聊天記錄</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody
                        maxH="70vh"
                        overflowY="auto"
                        ref={messagesContainerRef}
                        onScroll={async (e) => {
                            if (loadingMoreMessages || !hasMoreMessages) return;

                            const {scrollTop} = e.currentTarget;
                            if (scrollTop === 0) {
                                setLoadingMoreMessages(true);

                                const q = query(
                                    collection(firestore, "chatroom-messages"),
                                    where("chatroomId", "==", selectedChat),
                                    orderBy("createdAt", "desc"),
                                    startAfter(oldestMessageTimestamp),
                                    limit(100)
                                );

                                console.log('Loading more messages...');
                                const snapshot = await getDocs(q);
                                console.log('More messages query snapshot:', snapshot.size);
                                const olderMessages = snapshot.docs.map(doc => ({
                                    id: doc.id,
                                    ...doc.data(),
                                    formattedTime: doc.data().createdAt && typeof doc.data().createdAt.toDate === 'function'
                                        ? doc.data().createdAt.toDate().toLocaleString('zh-TW', {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: false
                                        })
                                        : ''
                                }));
                                console.log('Processed older messages:', olderMessages);

                                if (olderMessages.length > 0) {
                                    console.log('New oldest timestamp:', olderMessages[olderMessages.length - 1].createdAt);
                                    setOldestMessageTimestamp(olderMessages[olderMessages.length - 1].createdAt);
                                    setMessages(prev => {
                                        console.log('Previous messages:', prev);
                                        const newMessages = [...olderMessages.reverse(), ...prev];
                                        console.log('Updated messages:', newMessages);
                                        return newMessages;
                                    });
                                }

                                setHasMoreMessages(olderMessages.length === 100);
                                console.log('Has more messages:', olderMessages.length === 100);
                                setLoadingMoreMessages(false);
                            }
                        }}
                    >
                        <VStack
                            align="stretch"
                            spacing={4}
                            pb={4}
                            height="full"
                            justifyContent="flex-end"
                        >
                            <Box flex={1}>
                                {loadingMoreMessages && (
                                    <Center pt={2}>
                                        <CircularProgress size="20px" isIndeterminate/>
                                    </Center>
                                )}
                                {messages === null ? (
                                    <Center>
                                        <CircularProgress isIndeterminate/>
                                    </Center>
                                ) : (
                                    <VStack align="stretch" spacing={4}>
                                        {messages.map((message) => (
                                            <ChatMessage
                                                key={message.id}
                                                message={message}
                                                creator={creator}
                                            />
                                        ))}
                                    </VStack>
                                )}
                            </Box>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </VStack>
    );
};
