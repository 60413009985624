import { createContext, useContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import {
  connectFirestoreEmulator,
  doc,
  getDoc,
  initializeFirestore,
  onSnapshot,
  persistentLocalCache,
  persistentSingleTabManager,
} from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

const FirebaseContext = createContext();
const IS_EMULATOR = process.env.REACT_APP_EMULATOR_ENABLE === "true";
const app = initializeApp({
  apiKey: IS_EMULATOR ? "test-api-key" : process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

const auth = getAuth(app);
const storage = getStorage(app);
const firestore = initializeFirestore(app, {
  localCache: persistentLocalCache({
    tabManager: persistentSingleTabManager(),
    cacheSizeBytes: 100 * 1024 * 1024, // 100 MB
  }),
});
const functions = getFunctions(app, "asia-east1");
// 開發狀態下，連接 Emulator
if (IS_EMULATOR) {
  connectAuthEmulator(auth, "http://127.0.0.1:9099", {
    disableWarnings: true,
  });
  connectFirestoreEmulator(firestore, "127.0.0.1", 8080);
  connectStorageEmulator(storage, "127.0.0.1", 9199);
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

export const FirebaseProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        const docRef = doc(firestore, `users/${user.uid}`);
        getDoc(docRef).then((docSnap) => {
          setUserInfo(docSnap.data());
          setIsAuthLoading(false);
        });
        const unsubscribe = onSnapshot(docRef, (doc) => {
          setUserInfo(doc.data());
        });
        return () => unsubscribe();
      } else {
        setUserInfo(null);
        setIsAuthLoading(false);
      }
    });
  }, []);

  const value = {
    user,
    userInfo,
    app,
    auth,
    isAuthLoading,
    firestore,
    storage,
    functions,
  };
  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};

export function useFirebase() {
  return useContext(FirebaseContext);
}
